import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import routes,{ RouterConfigModel } from "./routes";
import Menu from '../components/Menu'

export default class AuthHOC extends React.Component<any>{
  render() {
    const { location, config } = this.props;

    const { pathname } = location;
    const isLogin = localStorage.getItem('__aurochs_token')
    let routePath = pathname
    const targetRouterConfig = config.find((v: RouterConfigModel) => {
      // 支持隐式传参
      if (v.regexp && pathname.match(v.regexp) != null) {
        routePath = v.path
        return true
      }
      // 其他情况直接进行比较
      return v.path === pathname
    });
    if (targetRouterConfig && !targetRouterConfig.auth && !isLogin) {
      const { component } = targetRouterConfig;
      return <Route exact path={routePath} component={component} />
    }
    if (isLogin) {
      return <Menu><Route path={routePath} component={targetRouterConfig?.component} /></Menu>
    }
    return <Redirect to="/login" />
  }
}