import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { ConnectorNames } from '@metaswap/uikit'
import Web3 from 'web3'
import getNodeUrl, { getBridgeUrl } from './getRpcUrl'

const POLLING_INTERVAL = 12000
const rpcUrl = getNodeUrl()
const bridgeUrl = `https://${getBridgeUrl()}.bridge.walletconnect.org/`
const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)

const injected = new InjectedConnector({ supportedChainIds: [chainId] })

const walletconnect = new WalletConnectConnector({
  rpc: { [chainId]: rpcUrl },
  bridge:  bridgeUrl,
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})

console.info(walletconnect,'---')
const bscConnector = new BscConnector({ supportedChainIds: [chainId] })

const walletlink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
  appName: "baby-frontend"
});

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
  [ConnectorNames.CoinbaseWallet]: walletlink,
}

export const getLibrary = (provider): Web3 => {
  return provider
}
