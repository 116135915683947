import React, { lazy } from 'react'

const Home = lazy(() => import('views/Home'))
const Farms = lazy(() => import('views/Farms'))
const Stake = lazy(() => import('views/Stake'))
const Ido = lazy(() => import('views/Ido'))
const PoolFrom = lazy(() => import('views/Pools'))
const Tokens = lazy(() => import('views/Tokens'))
const Banner = lazy(() => import('views/Banner'))
const NewBanner = lazy(() => import('views/NewBanner'))
const AddBanner = lazy(() => import('views/Banner/add'))
const AddNewBanner = lazy(() => import('views/NewBanner/add'))
const Login = lazy(() => import('views/Login'))
const NotFound = lazy(() => import('views/NotFound'))
const BottlesManage = lazy(() => import('views/BottlesManage'))
const BottlesEvent = lazy(() => import('views/BottlesEvent'))
const SwapfeeManage = lazy(() => import('views/SwapfeeManage'))
const AddDistoryPair = lazy(() => import('views/SwapfeeManage/AddDistoryPair'))
const MarketManage = lazy(()=>import('views/MarketManage'))
const AddCollection = lazy(()=>import('views/MarketManage/addCollection'))
const TokenApprove = lazy(() => import('views/TokenApprove'))
const LuckyBaby = lazy(() => import('views/LuckyBaby'))
const LandImage = lazy(()=> import('views/Land'))
const LandBio = lazy(()=> import('views/LandBio'))
const TopGainer = lazy(() => import('views/TopGainer'))
const PriceBot = lazy(() => import('views/PriceBot'))
const Website= lazy(() => import('views/Website'))
const Referral= lazy(() => import('views/Referral'))
const Bottles= lazy(() => import('views/Bottles'))

export interface RouterConfigModel {
    path:string,
    component?:any,
    auth?:boolean,
    exact?:boolean,
    name?:string,
    regexp?:RegExp
  }
const routes:RouterConfigModel[] = [

    { name: "Home", path: "/", component:Home,auth:true,exact:true},
    { name: "login", path: "/login", component:Login,exact:false},
    { name: "ido", path: "/ido", component:Ido,auth:true,exact:false},
    { name: "luckybaby", path: "/luckybaby", component:LuckyBaby,auth:true,exact:false},
    { name: "farms", path: "/farms", component:Farms,auth:true,exact:false},
    { name: "stake", path: "/stake", component:Stake,auth:true,exact:false},
    { name: "poolFrom", path: "/pools", component: PoolFrom,auth:true, exact: false }, 
    { name: "tokens", path: "/tokens", component: Tokens,auth:true, exact: false }, 
    { name: "banner", path: "/banner", component: Banner,auth:true, exact: false },   
    { name: "NewBanner", path: "/newbanner", component: NewBanner,auth:true, exact: false },   
    { name: "Column", path: "/column", component: Bottles,auth:true, exact: false },  
    { name: "bottlesManage", path: "/bottlesManage", component: BottlesManage, auth:true, exact: false },
    { name: "bottlesManage", path: "/bottlesEvent", component: BottlesEvent, auth:true, exact: false },
    { name: "swapfeeManage", path: "/swapfeeManage", component: SwapfeeManage, auth:true, exact: false }, 
    { name: "addBanner", path: `/addbanner/:id`, component: AddBanner,auth:true, exact: false ,regexp: /\/addbanner\/.+?\/?/},  
    { name: "addNewBanner", path: `/addnewbanner/:id`, component: AddNewBanner,auth:true, exact: false ,regexp: /\/addnewbanner\/.+?\/?/},  
    { name: "addDistoryPair", path: "/addDistoryPair/:id", component: AddDistoryPair, auth:true, exact: false, regexp: /\/addDistoryPair\/.+?\/?/ },   
    { name: "MarketManage", path: "/marketManage", component: MarketManage, auth:true, exact: false }, 
    { name: "addCollection", path: "/addCollection/:id", component: AddCollection, auth:true, exact: false, regexp: /\/addCollection\/.+?\/?/ }, 
    { name: "tokenapprove", path: "/tokenapprove", component: TokenApprove, auth: true,  exact: false },
    { name: "topGainer", path: "/topgainer", component: TopGainer, auth: true,  exact: false },
    { name: "priceBot", path: "/priceBot", component: PriceBot, auth: true,  exact: false },
    { name: "website", path: "/website", component: Website, auth: true,  exact: false },
    { name: "landAudit", path: "/landaudit", component: LandImage,auth:true, exact: false }, 
    { name: "landBioAudit", path: "/landbioaudit", component: LandBio,auth:true, exact: false }, 
    { name: "landAudit", path: "/referral", component: Referral,auth:true, exact: false },   
       
    { name: "404", path: "/404", component: NotFound, exact: false },   
    
]

export default routes;