const tokens = {
    baby: {
      symbol: 'BABY',
      address: {
        56: '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
        97: '0x5F8339ec5993e19e7Ce5d3CC06abFEd1aaE05a87',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    usdt: {
      symbol: 'USDT',
      address: {
        56: '0x55d398326f99059fF775485246999027B3197955',
        97: '0x1bC64Fa1104C96e03F9F06483B64ab6251a8E9E2',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ltrbt: {
      symbol: 'LTRBT',
      address: {
        56: '0x17d749d3e2ac204a07e19d8096d9a05c423ea3af',
        97: '0x8aD33b595700870F59c65Ae60E222cCD0272bd89',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    '1inch': {
      symbol: '1INCH',
      address: {
        56: '0x111111111117dc0aa78b770fa6a738034120c302',
        97: '0x5A57b3CF462b477ee9b0907c330e41f624f8f2ee',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    aave: {
      symbol: 'AAVE',
      address: {
        56: '0xfb6115445bff7b52feb98650c87f44907e58f802',
        97: '0x7041817D3791dC51626967a6aE174339F1E79F3F',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ada: {
      symbol: 'ADA',
      address: {
        56: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
        97: '0xf5F6F992ECd6c6504f103807146F176FF2542E72',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    alice: {
      symbol: 'ALICE',
      address: {
        56: '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
        97: '0xd70629896180f79b17A36B63c0a124A4707D7988',
      },
      decimals: 6,
      projectLink: 'https://babyswap.finance/',
    },
    alpaca: {
      symbol: 'ALPACA',
      address: {
        56: '0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
        97: '0xa6E68caf787770555C6A1cA654286b09cbc5ae1c',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    alpha: {
      symbol: 'ALPHA',
      address: {
        56: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
        97: '0xa1E8A3Dba46cce98772ED65e5335648ff4D3e197',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ankr: {
      symbol: 'ANKR',
      address: {
        56: '0xf307910a4c7bbc79691fd374889b36d8531b08e3',
        97: '0x8aeF15D70Ddb988C6Bd1a46f2152469F276f8441',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    arpa: {
      symbol: 'ARPA',
      address: {
        56: '0x6f769e65c14ebd1f68817f5f1dcdb61cfa2d6f7e',
        97: '0x60BBCa75E5C87e9c10b1fdd77bcD74a0cf3C3B14',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    asr: {
      symbol: 'ASR',
      address: {
        56: '0x80d5f92c2c8c682070c95495313ddb680b267320',
        97: '0x0B555d9ced42f1cb76B15647CBe2bD4a4bA8dD17',
      },
      decimals: 2,
      projectLink: 'https://babyswap.finance/',
    },
    ass: {
      symbol: 'ASS',
      address: {
        56: '0x7c63f96feafacd84e75a594c00fac3693386fbf0',
        97: '0xaF21D4e133df19A3f6EEdEd989AC025a6D930AE5',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    atm: {
      symbol: 'ATM',
      address: {
        56: '0x25e9d05365c867e59c1904e7463af9f312296f9e',
        97: '0xA5c78b6dDa9Dc22882e95c944591269c0a6D9866',
      },
      decimals: 2,
      projectLink: 'https://babyswap.finance/',
    },
    atom: {
      symbol: 'ATOM',
      address: {
        56: '0x0eb3a705fc54725037cc9e008bdede697f62f335',
        97: '0x5528f7EC31F97264389F1E595f035ce3796667a7',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    auction: {
      symbol: 'AUCTION',
      address: {
        56: '0x48dc0190df5ece990c649a7a07ba19d3650a9572',
        97: '0x28FEAE4de807D4ce1627c2565805171410e10184',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    auto: {
      symbol: 'AUTO',
      address: {
        56: '0xa184088a740c695e156f91f5cc086a06bb78b827',
        97: '0x3aD6d23B25D9E9506362443464b5bFF132E86807',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    avax: {
      symbol: 'AVAX',
      address: {
        56: '0xd26649b3eb22eb275326a8cb052d2f4736c863cf',
        97: '0xb224c2f1734800576629C59a03df1fd64EEC8062',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    bake: {
      symbol: 'BAKE',
      address: {
        56: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
        97: '0x927876a3C961eDb0b143aD8F8d905e2844258c75',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    banana: {
      symbol: 'BANANA',
      address: {
        56: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        97: '0x86ABc8D4db2b3D01f18976BD62466F51C298FA1c',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    band: {
      symbol: 'BAND',
      address: {
        56: '0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18',
        97: '0xa97Fa555c61b5AA188b63bA8B2AD4Cb63492f876',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bat: {
      symbol: 'BAT',
      address: {
        56: '0x101d82428437127bf1608f699cd651e6abf9766e',
        97: '0x03A80A1907bf586C08d655944839E40B09b13743',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bch: {
      symbol: 'BCH',
      address: {
        56: '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf',
        97: '0x81D9a0C73a16DbEE1482A17158707BF7dD7f5480',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bdo: {
      symbol: 'BDO',
      address: {
        56: '0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
        97: '0xA48Fb33C22D3431D5C705Fc9aF5f04EB0E369dbE',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bel: {
      symbol: 'BEL',
      address: {
        56: '0x8443f091997f06a61670b735ed92734f5628692f',
        97: '0xf91336498a1DFC45b2fb56bc0935cdB459d5B736',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    belt: {
      symbol: 'BELT',
      address: {
        56: '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
        97: '0xe805d2Ca9Df15E0beA88D4e36317836B85E33b3b',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    beth: {
      symbol: 'BETH',
      address: {
        56: '0x250632378e573c6be1ac2f97fcdf00515d0aa91b',
        97: '0x7Aee29fa64EC9594d85822313BdE187C2d2826ca',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bfi: {
      symbol: 'BFI',
      address: {
        56: '0x81859801b01764d4f0fa5e64729f5a6c3b91435b',
        97: '0x921e7C039D5cBC6DD73d3a32458a70e6635D335E',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bifi: {
      symbol: 'BIFI',
      address: {
        56: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
        97: '0x94efB010ACF9D60b9DC98343C5F856AFe2465BED',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    blk: {
      symbol: 'BLK',
      address: {
        56: '0x63870a18b6e42b01ef1ad8a2302ef50b7132054f',
        97: '0xbe78fB641C2726268063750CEC720920eEFAd212',
      },
      decimals: 6,
      projectLink: 'https://babyswap.finance/',
    },
    bmxx: {
      symbol: 'bMXX',
      address: {
        56: '0x4131b87f74415190425ccd873048c708f8005823',
        97: '0x89B0c9F6BA97b238984782B37d628764719BfF08',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bog: {
      symbol: 'BOG',
      address: {
        56: '0xd7b729ef857aa773f47d37088a1181bb3fbf0099',
        97: '0xFd580495cc525B9959F11Ffc4E69b7392B6733a6',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bondly: {
      symbol: 'BONDLY',
      address: {
        56: '0x96058f8c3e16576d9bd68766f3836d9a33158f89',
        97: '0xDd36a19Ee1d582c5F1702D9a6c39E5d6789cB8Ef',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bonfire: {
      symbol: 'BONFIRE',
      address: {
        56: '0x5e90253fbae4dab78aa351f4e6fed08a64ab5590',
        97: '0x45Aa26E36d1DeD94a56f72d7857DFbE4FcD829a1',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    bor: {
      symbol: 'BOR',
      address: {
        56: '0x92d7756c60dcfd4c689290e8a9f4d263b3b32241',
        97: '0xb2D9573a533CF604d2E18955E242BBAf9ED65c7C',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bp: {
      symbol: 'BP',
      address: {
        56: '0xacb8f52dc63bb752a51186d1c55868adbffee9c1',
        97: '0xe54d26e0EcF74fa857958EC2eFbc4cd057Bd3318',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    broobee: {
      symbol: 'bROOBEE',
      address: {
        56: '0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe',
        97: '0xE2Ede251182D234dE58ca87B281Dd9E0e2c2a98b',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bry: {
      symbol: 'BRY',
      address: {
        56: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
        97: '0xffD92da582A1fbBDCD442D4c3fF48BAb26F6975D',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bscpad: {
      symbol: 'BSCPAD',
      address: {
        56: '0x5a3010d4d8d3b5fb49f8b6e57fb9e48063f16700',
        97: '0x31b0557582E886781214372ebc84D7f608C771a4',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bscs: {
      symbol: 'BSCS',
      address: {
        56: '0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d',
        97: '0xD777b3Dc06fcE90dD775362C362110D44CEC6254',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bscx: {
      symbol: 'BSCX',
      address: {
        56: '0x5ac52ee5b2a633895292ff6d8a89bb9190451587',
        97: '0x1C464615c57a5c3662B339DAdE3fFa9748155D49',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    btc: {
      symbol: 'BTC',
      address: {
        56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        97: '0x46d2ADb56a81D5CC56a74594f8f62ea6F68130cb',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    btcst: {
      symbol: 'BTCST',
      address: {
        56: '0x78650b139471520656b9e7aa7a5e9276814a38e9',
        97: '0x29E585abB34dE9eA00C179837d163Ad29667B97d',
      },
      decimals: 17,
      projectLink: 'https://babyswap.finance/',
    },
    btt: {
      symbol: 'BTT',
      address: {
        56: '0x8595f9da7b868b1822194faed312235e43007b49',
        97: '0x64967B869e5835392f7717E63F8Cd1842546502d',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bunny: {
      symbol: 'BUNNY',
      address: {
        56: '0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
        97: '0xdB30F7D9C7996F35173e082949403620c7C7655a',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    burger: {
      symbol: 'BURGER',
      address: {
        56: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
        97: '0x09caE0dEDAbf08b7e478781b5F868CE89ec9f307',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    busd: {
      symbol: 'BUSD',
      address: {
        56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        97: '0x5f6863b93314482360201822fC5789E5a2b4051c',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bux: {
      symbol: 'BUX',
      address: {
        56: '0x211ffbe424b90e25a15531ca322adf1559779e45',
        97: '0xe64812c63C8BEc7AaA82bf409Ed47b82288c78b2',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    bzrx: {
      symbol: 'BZRX',
      address: {
        56: '0x4b87642aedf10b642be4663db842ecc5a88bf5ba',
        97: '0x880C9D045133FF7ee6f16b06E561f252aD41d997',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    cake: {
      symbol: 'CAKE',
      address: {
        56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        97: '0x74fD14EC5092b05D5bEDedE4b25E7b8540d7e440',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    cate: {
      symbol: 'CATE',
      address: {
        56: '0x118f073796821da3e9901061b05c0b36377b877e',
        97: '0x90Cadbe96Ca1EbAdC854905B4bFC691fD9b8C101',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    celr: {
      symbol: 'CELR',
      address: {
        56: '0x1f9f6a696c6fd109cd3956f45dc709d2b3902163',
        97: '0x0715b471D5D2f9B7c7f154c62618d70E0a99f47A',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    cfx: {
      symbol: 'CFX',
      address: {
        56: '0x045c4324039dA91c52C55DF5D785385Aab073DcF',
        97: '0xa5D376e9b630814B469982a047786779A86898Ab',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    chad: {
      symbol: 'CHAD',
      address: {
        56: '0x9E1892eD30796FF96aBBb1b6A5e722C6ea9C46eB',
        97: '0xaB15d0865d8A573dF515d1da93c3Bf385eD92b20',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    change: {
      symbol: 'CHANGE',
      address: {
        56: '0x6E1b4BA8F5bE7708cD475795FC23924eD078A8d2',
        97: '0x6313914473FaB9EC1e3Aa829EF7C302b4D5b124b',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    chibi: {
      symbol: 'Chibi Inu',
      address: {
        56: '0x504beaf9b74f5e38b0ac2335c51b8bb6e86f0ec5',
        97: '0x62A48fBaEa94B369A0600A19ABdF15C6Fa4a1fcB',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    chr: {
      symbol: 'CHR',
      address: {
        56: '0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
        97: '0x4AF066Cf9fc542C58A8cf725A14f3bb004D52F3e',
      },
      decimals: 6,
      projectLink: 'https://babyswap.finance/',
    },
    clu: {
      symbol: 'CLU',
      address: {
        56: '0x1162e2efce13f99ed259ffc24d99108aaa0ce935',
        97: '0xC3fA7DBd8Da783b02A84F9170f5b6591caEF82AE',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    cocos: {
      symbol: 'COCOS',
      address: {
        56: '0xab301dae71f5b386c566f484e636aee60318f12f',
        97: '0x28796b15AaA8529293Ee5cEa222B1EE536E2a5cE',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    comp: {
      symbol: 'COMP',
      address: {
        56: '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
        97: '0xF877e9ebAf623873EAD912131C66253C8e3Dd132',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    cos: {
      symbol: 'COS',
      address: {
        56: '0x96dd399f9c3afda1f194182f71600f1b65946501',
        97: '0x2B37Ee45DcB0f8229c3119d1F5BC3080e7B9e0ED',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    coti: {
      symbol: 'COTI',
      address: {
        56: '0xadbaf88b39d37dc68775ed1541f1bf83a5a45feb',
        97: '0xC2895B951a447595B47CcdF17a881E688c509906',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    cream: {
      symbol: 'CREAM',
      address: {
        56: '0xd4cb328a82bdf5f03eb737f37fa6b370aef3e888',
        97: '0x7D40c27D3FED71dBB5fFE2a3821D276549C55968',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ctk: {
      symbol: 'CTK',
      address: {
        56: '0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929',
        97: '0x1D7DFd209DE5A507A05A943BcE0b467B2EcCb092',
      },
      decimals: 6,
      projectLink: 'https://babyswap.finance/',
    },
    ctsi: {
      symbol: 'CTSI',
      address: {
        56: '0x8da443f84fea710266c8eb6bc34b71702d033ef2',
        97: '0xcaeEC48f8a5F20DaA2862ad6BA37D69616a0981B',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ctt: {
      symbol: 'CTT',
      address: {
        56: '0x464863745ed3af8b9f8871f1082211c55f8f884d',
        97: '0xD88bCd67D1ce940e372Ee7b29403C194A9A8a50E',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    cummies: {
      symbol: 'CUMMIES',
      address: {
        56: '0x27Ae27110350B98d564b9A3eeD31bAeBc82d878d',
        97: '0x1cc268E73fd3F68bB28422A25115E2d01a0870C8',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    cws: {
      symbol: 'CWS',
      address: {
        56: '0xbcf39f0edda668c58371e519af37ca705f2bfcbd',
        97: '0x0d002e7D0E9bf4bAA4f3A8DAAB5BE14571a4164b',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    cyc: {
      symbol: 'CYC',
      address: {
        56: '0x810ee35443639348adbbc467b33310d2ab43c168',
        97: '0x6850D9C5337162f3F41C46FeB2b76d249447CE5a',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    dai: {
      symbol: 'DAI',
      address: {
        56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        97: '0xd44369Fd6eaBA8D2E5C8dF74866D0f9Af2e7E152',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    dch: {
      symbol: 'DCH',
      address: {
        56: '0x14ace3d3429408bfa8562099a253172913ad71bd',
        97: '0xEcC4B1D9b00b04dc1Bdb68B01C60874534b1f8fC',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    dego: {
      symbol: 'DEGO',
      address: {
        56: '0x3fda9383a84c05ec8f7630fe10adf1fac13241cc',
        97: '0x98c70cbD1d326C42B7E153212bC358ca08E81857',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    deri: {
      symbol: 'DERI',
      address: {
        56: '0xe60eaf5a997dfae83739e035b005a33afdcc6df5',
        97: '0x89f39Ab662AA089278B287883CAFF52f3127a516',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    dexe: {
      symbol: 'DEXE',
      address: {
        56: '0x039cb485212f996a9dbb85a9a75d898f94d38da6',
        97: '0x5EaD57CbC9FD8001C16Be0147bdcD1D6Be23e2dF',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    dfd: {
      symbol: 'DFD',
      address: {
        56: '0x9899a98b222fcb2f3dbee7df45d943093a4ff9ff',
        97: '0x933EfAD536907D738D5631d0d4310DE28f0E2Ec1',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    dft: {
      symbol: 'DFT',
      address: {
        56: '0x42712dF5009c20fee340B245b510c0395896cF6e',
        97: '0x7CBE7413B478F21F07836B1F42100825b6E14348',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ditto: {
      symbol: 'DITTO',
      address: {
        56: '0x233d91A0713155003fc4DcE0AFa871b508B3B715',
        97: '0x9e26d33C903De766a05adE2fa6cDa5322f1D38d9',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    dodo: {
      symbol: 'DODO',
      address: {
        56: '0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
        97: '0x79b26EF209989F960b848E69757271a036440e55',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    doge: {
      symbol: 'DOGE',
      address: {
        56: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
        97: '0x14ECd544460E9F04B137B312BB4900C37463C0A9',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    doggy: {
      symbol: 'DOGGY',
      address: {
        56: '0x74926b3d118a63f6958922d3dc05eb9c6e6e00c6',
        97: '0xd51C5726b5Ba79a88C1B5D6A605CFcCe13c32bba',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    dop: {
      symbol: 'DOP',
      address: {
        56: '0x844fa82f1e54824655470970f7004dd90546bb28',
        97: '0x5866627467eCeD0a5DA0bDFf88Ef74067aDc4C65',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    dot: {
      symbol: 'DOT',
      address: {
        56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
        97: '0x870B7431d961125332ac64Cc4a7DC4860E7Cd5e7',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    dusk: {
      symbol: 'DUSK',
      address: {
        56: '0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c',
        97: '0x2e1874061133af9Bf80d472552E55e96e0070a68',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    earnx: {
      symbol: 'EARNX',
      address: {
        56: '0x9c28e48ea9170d9a9cb76d338655b8bd4124bc7a',
        97: '0x9bf20aD593A2a3c8C7CF50A4b7933387f1884B72',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    ecp: {
      symbol: 'ECP',
      address: {
        56: '0x375483cfa7fc18f6b455e005d835a8335fbdbb1f',
        97: '0xC36E4c741efbE2a3Cd975AEFC26e5079E8553121',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    edoge: {
      symbol: 'EDOGE',
      address: {
        56: '0x163f182c32d24a09d91eb75820cde9fd5832b329',
        97: '0x311a522E39531D0639136779F14a58cC686f9780',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    egld: {
      symbol: 'EGLD',
      address: {
        56: '0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe',
        97: '0x3e0c7A3Ff51532302cc1a37AF95F1c06c102fD82',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ele: {
      symbol: 'ELE',
      address: {
        56: '0xacd7b3d9c10e97d0efa418903c0c7669e702e4c0',
        97: '0x816a3B5D54657FE7F89c5a3f00675dcC3Ce89732',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    elongate: {
      symbol: 'ELONGATE',
      address: {
        56: '0x2a9718deff471f3bb91fa0eceab14154f150a385',
        97: '0xD7bE48dBbC3F5C932692063e48898caE46dEf84D',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    eos: {
      symbol: 'EOS',
      address: {
        56: '0x56b6fb708fc5732dec1afc8d8556423a2edccbd6',
        97: '0x4f16Cdd35e636939c23a44d32221Cbec87312030',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    eps: {
      symbol: 'EPS',
      address: {
        56: '0xa7f552078dcc247c2684336020c03648500c6d9f',
        97: '0x7a610e250441FB967151F8AC4e981cCc99F2ad45',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    etc: {
      symbol: 'ETC',
      address: {
        56: '0x3d6545b08693dae087e957cb1180ee38b9e3c25e',
        97: '0xce3dF11671d103b3E700936ceaCC300CCCedA6EC',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    eth: {
      symbol: 'ETH',
      address: {
        56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        97: '0x1873a93FD9ad7e858942DF173763dfD8574bD3bE',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ez: {
      symbol: 'EZ',
      address: {
        56: '0x5512014efa6cd57764fa743756f7a6ce3358cc83',
        97: '0xc6825B59509F7e40f895b52F0C6F4688f37022E3',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    feg: {
      symbol: 'FEG',
      address: {
        56: '0xacfc95585d80ab62f67a14c566c1b7a49fe91167',
        97: '0x97D46D3AE9F7F583C7396f83d921439a656d0585',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    ffa: {
      symbol: 'FFA',
      address: {
        56: '0x1336f5d9bba2eb910d67c4d8da114256f8c04b2a',
        97: '0xa717E8C111bF94ce88190C1616Fe3A406927271f',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    fil: {
      symbol: 'FIL',
      address: {
        56: '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
        97: '0x9e5C156520036a0DF9f926B896b8bFE409eCf7eb',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    fine: {
      symbol: 'FINE',
      address: {
        56: '0x4e6415a5727ea08aae4580057187923aec331227',
        97: '0x514835b688C9835185680383704DF583A3ef07E6',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    fox: {
      symbol: 'FOX',
      address: {
        56: '0xFAd8E46123D7b4e77496491769C167FF894d2ACB',
        97: '0xE434C2384f2EA4D38f4BDF7c242f593018e64e30',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    free: {
      symbol: 'FREE',
      address: {
        56: '0x12e34cdf6a031a10fe241864c32fb03a4fdad739',
        97: '0x1AE446A68B7f17370459aB29ACbE63A522dC3948',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    front: {
      symbol: 'FRONT',
      address: {
        56: '0x928e55dab735aa8260af3cedada18b5f70c72f1b',
        97: '0xddB48fCd56F046e95dCd5f41E21B008A3fbD5b0d',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    fts: {
      symbol: 'FTS',
      address: {
        56: '0x6507458bb53aec6be863161641ec28739c41cc97',
        97: '0xD2fD18950288Be40f58c23f4943E039433369ACF',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    glch: {
      symbol: 'GLCH',
      address: {
        56: '0xF0902eB0049A4003793BAb33F3566A22D2834442',
        97: '0x728a4feed360031c4eFA8e0a119165bf94f4684D',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    gmr: {
      symbol: 'GMR',
      address: {
        56: '0x0523215dcafbf4e4aa92117d13c6985a3bef27d7',
        97: '0x6a05d8dd4BcFCA7941A1991460cc70e827419F83',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    guh: {
      symbol: 'GUH',
      address: {
        56: '0x42069c0cf4da25420fc4c9d9001ba5af7846ccfd',
        97: '0x9BE1987b96295ce307293a7e82A4C6911C6FcCAb',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    hakka: {
      symbol: 'HAKKA',
      address: {
        56: '0x1d1eb8e8293222e1a29d2c0e4ce6c0acfd89aaac',
        97: '0xe8CFdE7bB286f996ad76854FDa4D966451d68C4f',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    hard: {
      symbol: 'HARD',
      address: {
        56: '0xf79037f6f6be66832de4e7516be52826bc3cbcc4',
        97: '0xebe77Bf183F32d1462A9A8938d7Df8b290EE0Bd4',
      },
      decimals: 6,
      projectLink: 'https://babyswap.finance/',
    },
    helmet: {
      symbol: 'HELMET',
      address: {
        56: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
        97: '0x96947B8c9434CB1EF0BfB2f77553e44281A3Ffa3',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    hget: {
      symbol: 'HGET',
      address: {
        56: '0xc7d8d35eba58a0935ff2d5a33df105dd9f071731',
        97: '0xBB7F432066454099A99ac54C1d54253438886073',
      },
      decimals: 6,
      projectLink: 'https://babyswap.finance/',
    },
    hodl: {
      symbol: 'HODL',
      address: {
        56: '0x0e3eaf83ea93abe756690c62c72284943b96a6bc',
        97: '0x1e04A5a2007EA7d5f6BD373A79DAb36Aa2679e36',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    home: {
      symbol: 'HOME',
      address: {
        56: '0x57c9487f30d04aee52d323586ec9a23d05a1504e',
        97: '0x51581aB22f28034EBbAD1332Ff96f12E77e7aE28',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    hoo: {
      symbol: 'HOO',
      address: {
        56: '0xe1d1f66215998786110ba0102ef558b22224c016',
        97: '0x2C802F18f8bD9dda497830873e4CD7A944c729Ce',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    hotcross: {
      symbol: 'HOTCROSS',
      address: {
        56: '0x4fa7163e153419e0e1064e418dd7a99314ed27b6',
        97: '0x774817FD2bc821D2d608D264111af00204F63017',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    hzn: {
      symbol: 'HZN',
      address: {
        56: '0xc0eff7749b125444953ef89682201fb8c6a917cd',
        97: '0xf06a1b1Da4D9A0A295f29F6b842b7a0a6452b716',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    inj: {
      symbol: 'INJ',
      address: {
        56: '0xa2b726b1145a4773f68593cf171187d8ebe4d495',
        97: '0x50EB490A52f4C44A92966ad2C9849cD34920aCc7',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    iotx: {
      symbol: 'IOTX',
      address: {
        56: '0x9678e42cebeb63f23197d726b29b1cb20d0064e5',
        97: '0x8fFe29328567Fc3EA50D97ff7706BEdd81af9F85',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    jgn: {
      symbol: 'JGN',
      address: {
        56: '0xc13b7a43223bb9bf4b69bd68ab20ca1b79d81c75',
        97: '0x6A6AA616F8Ed00EF8ACe43040379c825830D6e5f',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    juld: {
      symbol: 'JULD',
      address: {
        56: '0x5a41f637c3f7553dba6ddc2d3ca92641096577ea',
        97: '0x736af4C87C661F489F81E472c918f73BD591e38e',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    juv: {
      symbol: 'JUV',
      address: {
        56: '0xc40c9a843e1c6d01b7578284a9028854f6683b1b',
        97: '0xFEaf6645176a60c85ac85C14f3eA7c6ED3d562eA',
      },
      decimals: 2,
      projectLink: 'https://babyswap.finance/',
    },
    kabosu: {
      symbol: 'KABOSU',
      address: {
        56: '0x4a824ee819955a7d769e03fe36f9e0c3bd3aa60b',
        97: '0x5604aBA5eECC4d7519De2195AAb2D54f0211E723',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    key: {
      symbol: 'KEY',
      address: {
        56: '0x85c128ee1feeb39a59490c720a9c563554b51d33',
        97: '0xa1DfDB57fDdF9016d6E4062C05E366863190fE30',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    knockers: {
      symbol: 'KNOCKERS',
      address: {
        56: '0x711f03c19e54133b1d940112580cc1e94e246f1f',
        97: '0x9CacA4F864eB6089d166dF794eBcFA1c01008167',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    ksm: {
      symbol: 'KSM',
      address: {
        56: '0x2aa69e8d25c045b659787bc1f03ce47a388db6e8',
        97: '0x8E2FfBfe473B2A814352Bb33Dd2D47640c2BeaCE',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    kun: {
      symbol: 'KUN',
      address: {
        56: '0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584',
        97: '0x3F6bF1F0e28E50107699602f95b9aCe2f691E696',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    las: {
      symbol: 'LAS',
      address: {
        56: '0x735ebe3ed47877a11023b904054a0ffe44e91ab7',
        97: '0xc5040c407D5bF9F37e8FDFb60Edd90Cfe44FfC54',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    lien: {
      symbol: 'LIEN',
      address: {
        56: '0x5d684adaf3fcfe9cfb5cede3abf02f0cdd1012e3',
        97: '0xcc6A165deDC7E0017d023C12955DE1fF24e3cE56',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    lina: {
      symbol: 'LINA',
      address: {
        56: '0x762539b45a1dcce3d36d080f74d1aed37844b878',
        97: '0xb05Cdbb3Bdbb7380Ba207F9f1da2cF88dD432Ed7',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    link: {
      symbol: 'LINK',
      address: {
        56: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
        97: '0x4eD3ACFb4487AcCed9B8aa6A50D8526853225855',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    lit: {
      symbol: 'LIT',
      address: {
        56: '0xb59490ab09a0f526cc7305822ac65f2ab12f9723',
        97: '0x46B3876E78DE5AaB87979Bbcdbc7C585Cda6a941',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    'little rabbit': {
      symbol: 'Little Rabbit',
      address: {
        56: '0x17d749d3e2ac204a07e19d8096d9a05c423ea3af',
        97: '0x8aD33b595700870F59c65Ae60E222cCD0272bd89',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    lmt: {
      symbol: 'LMT',
      address: {
        56: '0x9617857e191354dbea0b714d78bc59e57c411087',
        97: '0xdd39afb624E7757F4a44C6171183a9827bA4541C',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    lnchx: {
      symbol: 'LNCHX',
      address: {
        56: '0xc43570263e924c8cf721f4b9c72eed1aec4eb7df',
        97: '0x538F3F39Ad1508Bf74a739b0fEeCDa0Cfe03b7a3',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    lowb: {
      symbol: 'LOWB',
      address: {
        56: '0x843d4a358471547f51534e3e51fae91cb4dc3f28',
        97: '0xA1de98482f46d59DA23E7b8c092BECC6Af432451',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    lpool: {
      symbol: 'LPOOL',
      address: {
        56: '0xcfb24d3c3767364391340a2e6d99c64f1cbd7a3d',
        97: '0x70FBE0B7f66d3256B575430FBA4C9C2891BFd10c',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ltc: {
      symbol: 'LTC',
      address: {
        56: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
        97: '0x79EFB3C65E9589D0a3a0D8220F2236Fe23589EFF',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    lto: {
      symbol: 'LTO',
      address: {
        56: '0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd',
        97: '0xF2Cd384051a1E34B2062aE33D4E0A9586E0D2148',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    math: {
      symbol: 'MATH',
      address: {
        56: '0xf218184af829cf2b0019f8e6f0b2423498a36983',
        97: '0x023722c01afb66F9c674F0d8B0A310689574269f',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    matic: {
      symbol: 'MATIC',
      address: {
        56: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
        97: '0x9F3B9516a11F6115F6140a727ACF768da7Bf0b65',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    mbox: {
      symbol: 'MBOX',
      address: {
        56: '0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
        97: '0xF86350a691CD563523f62bb09ff30FdD21740dac',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    mdt: {
      symbol: 'MDT',
      address: {
        56: '0x668db7aa38eac6b40c9d13dbe61361dc4c4611d1',
        97: '0x138f91b0DFDD8CD36aEbd5FC8E23828c96340252',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    mdx: {
      symbol: 'MDX',
      address: {
        56: '0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
        97: '0xA6F96900a50f19c395eA6ba1F490fc31b7576e56',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    mepad: {
      symbol: 'MEPAD',
      address: {
        56: '0x9d70a3ee3079a6fa2bb16591414678b7ad91f0b5',
        97: '0xF3D9f9a4E1DD93CD858d94C96A735DD915e1d691',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    merl: {
      symbol: 'MERL',
      address: {
        56: '0xda360309c59cb8c434b28a91b823344a96444278',
        97: '0xaA7680062b3DCa9005aAfE9cd8Dc6cc5943727Bf',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    metamoon: {
      symbol: 'METAMOON',
      address: {
        56: '0xa1a0c7849e6916945a78f8af843738c051ab15f3',
        97: '0x788334bd41A8D4b71051eaD4b373C22FEBB14B90',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    mir: {
      symbol: 'MIR',
      address: {
        56: '0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
        97: '0x7CC9FEff32211BF7653c9407Ad8877910d850B95',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    mkr: {
      symbol: 'MKR',
      address: {
        56: '0x5f0da599bb2cccfcf6fdfd7d81743b6020864350',
        97: '0x7fD49Bfe2078A6e19dad5C944Af9aa1d2C2583F8',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    mndao: {
      symbol: 'MNDAO',
      address: {
        56: '0x069b2619eb24367a46fda638bd1b88aa4dad7879',
        97: '0xaB54C34c5F0810A9a98c820c322ec962039122b6',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    moonshot: {
      symbol: 'MOONSHOT',
      address: {
        56: '0xd27d3f7f329d93d897612e413f207a4dbe8bf799',
        97: '0x93Aa597e304F793f6cF8a27fa89e152f9A85ef51',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    moonstar: {
      symbol: 'MOONSTAR',
      address: {
        56: '0xce5814efff15d53efd8025b9f2006d4d7d640b9b',
        97: '0xb2ec8104DBe8DB04C80C58409A3a94bf7984f455',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    moontoken: {
      symbol: 'MOONTOKEN',
      address: {
        56: '0x81E4d494b85A24a58a6BA45c9B418b32a4E039de',
        97: '0x9074489E7489081D2F29C04B7530215fAcC2a303',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    mx: {
      symbol: 'MX',
      address: {
        56: '0x9f882567a62a5560d147d64871776eea72df41d3',
        97: '0x76677Dc8839E16F90A0Bc21bf25cb9625b5820A0',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    naft: {
      symbol: 'NAFT',
      address: {
        56: '0xd7730681b1dc8f6f969166b29d8a5ea8568616a3',
        97: '0xc6Fe55E5DbBf6d9E17A9bEf755174ef41c3da608',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ncat: {
      symbol: 'NCAT',
      address: {
        56: '0x0cf011a946f23a03ceff92a4632d5f9288c6c70d',
        97: '0x1BC1F3d8F960bA9C6c5cA137B0483f98dfb7aA30',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    ncc: {
      symbol: 'NCC ',
      address: {
        56: '0x93dfc1e09b7164bafd4860963b6d94cbc4284774',
        97: '0x700CB3c25311640bf3f21B77DeE986C47387Dc7c',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    near: {
      symbol: 'NEAR',
      address: {
        56: '0x1fa4a73a3f0133f0025378af00236f3abdee5d63',
        97: '0x8F3D90D178038beA9c7789008Be9114f14fac353',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    nftart: {
      symbol: 'NFTART',
      address: {
        56: '0xf7844cb890f4c339c497aeab599abdc3c874b67a',
        97: '0xADf909694d47330F1bD88678a1E0D07323aE4265',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    nrv: {
      symbol: 'NRV',
      address: {
        56: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
        97: '0xEe6B52A48C106A239dC52B3DF547eA883c1CF679',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    nuls: {
      symbol: 'NULS',
      address: {
        56: '0x8cd6e29d3686d24d3c2018cee54621ea0f89313b',
        97: '0x20eda743d6BBcB347ff43c8FA170323ea45Aa304',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    octa: {
      symbol: 'OCTA',
      address: {
        56: '0x86c3E4FfAcdB3AF628ef985a518cd6ee22A22b28',
        97: '0x4c283db7D99Ef397C92c711884f084BCEb96Ce25',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    oddz: {
      symbol: 'ODDZ',
      address: {
        56: '0xcd40f2670cf58720b694968698a5514e924f742d',
        97: '0xB6fECcA2A42ebF0e8099A392668A0C507Fc7dfEe',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    og: {
      symbol: 'OG',
      address: {
        56: '0xf05e45ad22150677a017fbd94b84fbb63dc9b44c',
        97: '0xaae3400904CfEf311335EEc4499c0C07A4d0BCB6',
      },
      decimals: 2,
      projectLink: 'https://babyswap.finance/',
    },
    om: {
      symbol: 'OM',
      address: {
        56: '0xf78d2e7936f5fe18308a3b2951a93b6c4a41f5e2',
        97: '0x27495B0aAFDCF7fd574646d3c4412136A8095620',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ont: {
      symbol: 'ONT',
      address: {
        56: '0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
        97: '0x4dF2cbad9Bc842fBC6e09c25622B968C86fC11a4',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    orange: {
      symbol: 'ORANGE',
      address: {
        56: '0xacab3ee32aacdbc745667fb261ed7af1de5c5ecd',
        97: '0x15372d2FC6bE479120EB9258cbe04b2D54a621A5',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    ork: {
      symbol: 'ORK',
      address: {
        56: '0xced0ce92f4bdc3c2201e255faf12f05cf8206da8',
        97: '0xfF51E4F88d97297f80a6a148f63711dDF6EB1471',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    orn: {
      symbol: 'ORN',
      address: {
        56: '0xe4ca1f75eca6214393fce1c1b316c237664eaa8e',
        97: '0xD78904b838f2D9F64fe213A6449E573F35a3AF3e',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    oxb: {
      symbol: 'OXB',
      address: {
        56: '0x3fd5b5746315e3f8d43a46b09c826a001ebb977d',
        97: '0xb8bC8159dEf3bc3E47F6862f0cc28386De03b16a',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    panther: {
      symbol: 'PANTHER',
      address: {
        56: '0x1f546ad641b56b86fd9dceac473d1c7a357276b7',
        97: '0x632a9dD75375136be8B89B6d46C60a483AdC6079',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    para: {
      symbol: 'PARA',
      address: {
        56: '0x076ddce096c93dcf5d51fe346062bf0ba9523493',
        97: '0x1b8f3b7C5fAB7C08A3726d9060a5E42a882a800c',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    pax: {
      symbol: 'PAX',
      address: {
        56: '0xb7f8cd00c5a06c0537e2abff0b58033d02e5e094',
        97: '0x9aB94974EfEc7709feA7777D3467714F2c070DB5',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    peg: {
      symbol: 'PEG',
      address: {
        56: '0xd585f9c5953ca97da3551f20725a274c9e442ff3',
        97: '0xC38E61Ce3C7FAccB8F351BCdfe425483eD91D870',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    perl: {
      symbol: 'PERL',
      address: {
        56: '0x0f9e4d49f25de22c2202af916b681fbb3790497b',
        97: '0x52fC13848158D1AA23F3fd58C14a39E9CAF03830',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    perp: {
      symbol: 'PERP',
      address: {
        56: '0xbd983c014235b911be783237da3897a796b6f1cf',
        97: '0x95f2Ac5354C3Fa96c22Eeeea9f0A479e0DfE6c59',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    pha: {
      symbol: 'PHA',
      address: {
        56: '0xc0598075bb51d50ca0aa41c8f6174b0dec746e6e',
        97: '0x5CA6d8F49AE76A7699935f8AdFfB41Fd7657036E',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    pig: {
      symbol: 'PIG',
      address: {
        56: '0x8850d2c68c632e3b258e612abaa8fada7e6958e5',
        97: '0x673AFc3b911426b0EAe4DCb3A839576429aBb39f',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    pols: {
      symbol: 'POLS',
      address: {
        56: '0x7e624fa0e1c4abfd309cc15719b7e2580887f570',
        97: '0xDDB1e341B7aD2aBDaC0b850A0C49a83643ddDb3f',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    poocoin: {
      symbol: 'POOCOIN',
      address: {
        56: '0xb27adaffb9fea1801459a1a81b17218288c097cc',
        97: '0xA993E8081968079d8954ACb0DaE08c1E3092AEB6',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    prom: {
      symbol: 'PROM',
      address: {
        56: '0xaf53d56ff99f1322515e54fdde93ff8b3b7dafd5',
        97: '0x22d2478042628F022c13baB8199295a760E12742',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    pros: {
      symbol: 'PROS',
      address: {
        56: '0xed8c8aa8299c10f067496bb66f8cc7fb338a3405',
        97: '0xf371C7C86e5d60E51188B99f136b14a3ce927d37',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    psg: {
      symbol: 'PSG',
      address: {
        56: '0xbc5609612b7c44bef426de600b5fd1379db2ecf1',
        97: '0x7Aea6C1dDA128D303F88bb890E756Cf04E7768f7',
      },
      decimals: 2,
      projectLink: 'https://babyswap.finance/',
    },
    raca: {
      symbol: 'RACA',
      address: {
        56: '0x043b49749e0016e965600d502e2177ca2d95b3d9',
        97: '0xd58eE30Be7Eb26E61cdC169366CB5584e6E11fF5',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    ramp: {
      symbol: 'RAMP',
      address: {
        56: '0x8519ea49c997f50ceffa444d240fb655e89248aa',
        97: '0xa646d1aC1564B2C9Ddeb94D180707F04d61D1e5c',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    reau: {
      symbol: 'REAU',
      address: {
        56: '0x4c79b8c9cB0BD62B047880603a9DEcf36dE28344',
        97: '0xAAe5398FD9AC37127596B80CED79906128BB5235',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    reef: {
      symbol: 'REEF',
      address: {
        56: '0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
        97: '0x747d03FA8e9Fa0d7e92DAE21B2678cc92D741955',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    renbtc: {
      symbol: 'RENBTC',
      address: {
        56: '0xfce146bf3146100cfe5db4129cf6c82b0ef4ad8c',
        97: '0x5FF65479C9115168cd0248830BaF41B41eA6927D',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    revo: {
      symbol: 'REVO',
      address: {
        56: '0x678e5f70b6b582dfadb3dbd68af17801d34555c5',
        97: '0x5B22eF887416137f551408ea86930Aa7B44aeBc2',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    rfox: {
      symbol: 'RFOX',
      address: {
        56: '0x0a3a21356793b49154fd3bbe91cbc2a16c0457f5',
        97: '0x41c06d8AAC38559b10196c9154653d7f982Af2a0',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    rich: {
      symbol: 'RICH',
      address: {
        56: '0xc7bc24c4c18f8251d31611114d0e7b5f5ef76762',
        97: '0xD2a7aF1f58dbC91c85F908a55370DAC590efC679',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    rmoon: {
      symbol: 'RMOON',
      address: {
        56: '0x78bc22a215c1ef8a2e41fa1c39cd7bdc09bd5174',
        97: '0x7C6044A2c0D50d6973595815B1022CB79fe60C73',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    safebtc: {
      symbol: 'SAFEBTC',
      address: {
        56: '0x380624A4a7e69dB1cA07deEcF764025FC224D056',
        97: '0x5087e9Ad00231C053e84C0A724b26165e6ff24bD',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    safemars: {
      symbol: 'SafeMars',
      address: {
        56: '0x3ad9594151886ce8538c1ff615efa2385a8c3a88',
        97: '0x6537323741Ae774D2242789F5b96B55b9Ea52995',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    safemoon: {
      symbol: 'SAFEMOON',
      address: {
        56: '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3',
        97: '0x12b6D516Eca4F86029AFE446E31dC7526d440352',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    safepluto: {
      symbol: 'SAFEPLUTO',
      address: {
        56: '0xf40153fb3f5db22f6843fc63835a3a870ed846d3',
        97: '0xe68d62Ce6305B5694bF0eC2eE187e68EecC667C6',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    sat: {
      symbol: 'SAT',
      address: {
        56: '0x1e446cbea52badeb614fbe4ab7610f737995fb44',
        97: '0xa8f1A33EE7f64231821d9F9656D1709fEb6E3A12',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    sbdo: {
      symbol: 'SBDO',
      address: {
        56: '0x0d9319565be7f53cefe84ad201be3f40feae2740',
        97: '0xc757197fd0CA112E4be018fa6D712fDFbA36833D',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    sfp: {
      symbol: 'SFP',
      address: {
        56: '0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
        97: '0x4dF52D3af1293D22529CA9Bdde8C710A5b040763',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    sfund: {
      symbol: 'SFUND',
      address: {
        56: '0x477bc8d23c634c154061869478bce96be6045d12',
        97: '0x053e9B54843Db643C21f9EB9f0Da3A862FdFa13d',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    shib: {
      symbol: 'SHIB',
      address: {
        56: '0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
        97: '0xDa34585178B73d9d616FecE9A01fab74418bCBeb',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    snm: {
      symbol: 'SNM',
      address: {
        56: '0x46d0dac0926fa16707042cadc23f1eb4141fe86b',
        97: '0x3E566c54e56B7cD9FDA023941150cDc935A06eE1',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    snx: {
      symbol: 'SNX',
      address: {
        56: '0x9ac983826058b8a9c7aa1c9171441191232e8404',
        97: '0x39b368B7aDA38795ee4e68be6aB192eB356bF16F',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    spe: {
      symbol: 'SPE',
      address: {
        56: '0xdbaaa36b347d56b77ce0e36f050fceebbf9fbc38',
        97: '0x0384595b1e8119c914852bF5E83798370Bc18521',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    sushi: {
      symbol: 'SUSHI',
      address: {
        56: '0x947950bcc74888a40ffa2593c5798f11fc9124c4',
        97: '0xE4F98565848795654D043079721d32674ca34D4C',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    suter: {
      symbol: 'SUTER',
      address: {
        56: '0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055',
        97: '0x3313A14D4F987cda2D40c56935756cf555a950f5',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    svt: {
      symbol: 'SVT',
      address: {
        56: '0x1e3f4174b6d226354e1fb2325e6f76c1919bd9eb',
        97: '0x007F1Fa20a3D5486f25EC9957301b56A79bfa676',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    swamp: {
      symbol: 'SWAMP',
      address: {
        56: '0xc5a49b4cbe004b6fd55b30ba1de6ac360ff9765d',
        97: '0x9d8728a27d7fDe4c5f50f0959dB474EdbA4F3dBD',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    swg: {
      symbol: 'SWG',
      address: {
        56: '0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
        97: '0xe308AE9C38feF88C22D6982595055b3C98445F4D',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    swth: {
      symbol: 'SWTH',
      address: {
        56: '0x250b211ee44459dad5cd3bca803dd6a7ecb5d46c',
        97: '0x0fe158f18d5bd13E31f2Dd9184EA55a282955C66',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    sxp: {
      symbol: 'SXP',
      address: {
        56: '0x47bead2563dcbf3bf2c9407fea4dc236faba485a',
        97: '0x27Ca8cD0691738a37dac04efd263A6D4a79EA7Ed',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    tct: {
      symbol: 'TCT',
      address: {
        56: '0xca0a9df6a8cad800046c1ddc5755810718b65c44',
        97: '0x545305AAEc1737d4EA4824BF9b8fb43773365Fbf',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    tko: {
      symbol: 'TKO',
      address: {
        56: '0x9f589e3eabe42ebc94a44727b3f3531c0c877809',
        97: '0x9AE9797cf6b28c43112Dd71263AE5536309E7E85',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    tlm: {
      symbol: 'TLM',
      address: {
        56: '0x2222227e22102fe3322098e4cbfe18cfebd57c95',
        97: '0x698e2B996Fcf6e9f683C630611fFBddBB3219b99',
      },
      decimals: 4,
      projectLink: 'https://babyswap.finance/',
    },
    tpad: {
      symbol: 'TPAD',
      address: {
        56: '0xadcfc6bf853a0a8ad7f9ff4244140d10cf01363c',
        97: '0x71FF88525Ff6B9375220248E764765B78FF9b19e',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    tpt: {
      symbol: 'TPT',
      address: {
        56: '0xeca41281c24451168a37211f0bc2b8645af45092',
        97: '0x4e78ff70707dd2fC8748FbC83603F1Ea7c6354f6',
      },
      decimals: 4,
      projectLink: 'https://babyswap.finance/',
    },
    trade: {
      symbol: 'TRADE',
      address: {
        56: '0x7af173F350D916358AF3e218Bdf2178494Beb748',
        97: '0x562e0f88C13f1aAD371116B3A80FCFB82BD31Aa6',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    trdg: {
      symbol: 'TRDG',
      address: {
        56: '0x92a42Db88Ed0F02c71D439e55962Ca7CAB0168b5',
        97: '0x29C759B2BA36e0D8b208f2ABA686480Abc533Cf2',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    tru: {
      symbol: 'TRU',
      address: {
        56: '0xb4fcdcb8a0b29247466720ff81587d1a6f4fc4d7',
        97: '0x229c00f6939d0Be003A0012D37d598329eD4DE4A',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    trx: {
      symbol: 'TRX',
      address: {
        56: '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
        97: '0x6afc03875cC081cee282E685A86101c5B5C8F219',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    tusd: {
      symbol: 'TUSD',
      address: {
        56: '0x14016e85a25aeb13065688cafb43044c2ef86784',
        97: '0x60CAe7c4bBa8b91547E689A14AE06800B4414000',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    twt: {
      symbol: 'TWT',
      address: {
        56: '0x4b0f1812e5df2a09796481ff14017e6005508003',
        97: '0xDF1dB76Ff86A8c0E55CF983dB627cbC5b43C6025',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ultra: {
      symbol: 'ULTRA',
      address: {
        56: '0x0b3f42481c228f70756dbfa0309d3ddc2a5e0f6a',
        97: '0x0de48e38de7Bd1fe17E246522C6068BBF0776d3B',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    uncx: {
      symbol: 'UNCX',
      address: {
        56: '0x09a6c44c3947b69e2b45f4d51b67e6a39acfb506',
        97: '0xc99b4665cCeA858A3D66947D0FFb4cA136640079',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    unfi: {
      symbol: 'UNFI',
      address: {
        56: '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B',
        97: '0xB2043878fd1945540E89eE5811d1d64522BBF1d0',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    uni: {
      symbol: 'UNI',
      address: {
        56: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
        97: '0xF6E9e3896e1dB86C9dceb3FdB76B9E0f276167d3',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    usdc: {
      symbol: 'USDC',
      address: {
        56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        97: '0xE87Ae3a76Bd5a59DCB161401cBC096E161F6663A',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    ust: {
      symbol: 'UST',
      address: {
        56: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
        97: '0x696be16f3F0ba7BC0352E2EC4D7cD4da5aADd303',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    vai: {
      symbol: 'VAI',
      address: {
        56: '0x4bd17003473389a42daf6a0a729f6fdb328bbbd7',
        97: '0xc131174F40C6d37FE6821Ab5C8bD3AE1286F836e',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    vancat: {
      symbol: 'VANCAT',
      address: {
        56: '0x8597ba143AC509189E89aaB3BA28d661A5dD9830',
        97: '0xD73A03C70d88a582e0C4752b56579e8B5558969E',
      },
      decimals: 0,
      projectLink: 'https://babyswap.finance/',
    },
    vidt: {
      symbol: 'VIDT',
      address: {
        56: '0x3f515f0a8e93f2e2f891ceeb3db4e62e202d7110',
        97: '0xbca56846bC56C49d4945c33a64e3E28C4672F4c0',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    watch: {
      symbol: 'WATCH',
      address: {
        56: '0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0',
        97: '0xBAf4FC42D882E25b8003d1e07A948dDDaCb5CDCd',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    wbnb: {
      symbol: 'WBNB',
      address: {
        56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        97: '0xBD63eA93A81B8D5e4fa4E3443c7bED6b84FC00C2',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    well: {
      symbol: 'WELL',
      address: {
        56: '0xf07a32eb035b786898c00bb1c64d8c6f8e7a46d5',
        97: '0xBDb2f08663d18EAcf4e9E3A6C3BE323624b1B698',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    wex: {
      symbol: 'WEX',
      address: {
        56: '0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
        97: '0x4f003B275eBf4e28ea4A1efe553955fA29959162',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    win: {
      symbol: 'WIN',
      address: {
        56: '0xaef0d72a118ce24fee3cd1d43d383897d05b4e99',
        97: '0xe7b004B173027baC56C9EA750BCeCf2f15DA4ADC',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    woop: {
      symbol: 'WOOP',
      address: {
        56: '0x8b303d5bbfbbf46f1a4d9741e491e06986894e18',
        97: '0xB9b2fb75309F7Cd3d20CF28be57f81F09989949e',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    wrx: {
      symbol: 'WRX',
      address: {
        56: '0x8e17ed70334c87ece574c9d537bc153d8609e2a3',
        97: '0x63acD20ED1bf6e730F03e27909e9A0b0fd0A568a',
      },
      decimals: 8,
      projectLink: 'https://babyswap.finance/',
    },
    wsb: {
      symbol: 'WSB',
      address: {
        56: '0x22168882276e5d5e1da694343b41dd7726eeb288',
        97: '0x31807ea83Ce2e202274bA23c60F1287B0D9C998c',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    wsote: {
      symbol: 'WSOTE',
      address: {
        56: '0x541e619858737031a1244a5d0cd47e5ef480342c',
        97: '0xb1F550ACEADe14999b6E8B211A4e38Dfa1f9bcf9',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    'x-token': {
      symbol: 'X-TOKEN',
      address: {
        56: '0x1657223bb7b9d272957b3f3cf70f9c36a4c99ab9',
        97: '0x1378452E2481895d185B26abb7A855a063e466ea',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    xblzd: {
      symbol: 'XBLZD',
      address: {
        56: '0x9a946c3cb16c08334b69ae249690c236ebd5583e',
        97: '0xe15c38062886e0412C4A3BeeA7a7859E6163128a',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    xdoge: {
      symbol: 'XDOGE',
      address: {
        56: '0x4c0415A6e340eCCebff58131799C6c4127cc39FA',
        97: '0xA36925bbbe7D3bCB2a700a5815cb4b0Cd2ceBa29',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    xed: {
      symbol: 'XED',
      address: {
        56: '0x5621b5a3f4a8008c4ccdd1b942b121c8b1944f1f',
        97: '0xa2c62582A0f05827Aac928031fE83d8b3Fe3fC4c',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    xend: {
      symbol: 'XEND',
      address: {
        56: '0x4a080377f83D669D7bB83B3184a8A5E61B500608',
        97: '0x439ba097204d66A0484ceF62248552FC8313AE59',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    xlm: {
      symbol: 'XLM',
      address: {
        56: '0x43c934a845205f0b514417d757d7235b8f53f1b9',
        97: '0x41e31Fd240BB95d995c1aEE2338c797DD09f5E16',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    xmark: {
      symbol: 'XMARK',
      address: {
        56: '0x26a5dfab467d4f58fb266648cae769503cec9580',
        97: '0x15D9DAc5D0a46ae4D02B66a8222b4120f4b0E299',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    xpose: {
      symbol: 'XPOSE',
      address: {
        56: '0xcc10c8AfD683c5AA86B1d170d75B555bce5a2C37',
        97: '0x445398c3C17edCd47857eF5C6F992e607bCCEdB5',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    xrp: {
      symbol: 'XRP',
      address: {
        56: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
        97: '0xFa9AdBebc9409bBf6d4d6a9883b761808177530a',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    xtz: {
      symbol: 'XTZ',
      address: {
        56: '0x16939ef78684453bfdfb47825f8a5f714f12623a',
        97: '0x7FD26f37bE2f580aDf70b988e2B52870D76F18Dc',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    xvs: {
      symbol: 'XVS',
      address: {
        56: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
        97: '0xF7b6940b760e49DCFae7E56A52d3CAA81b3f0012',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    yfi: {
      symbol: 'YFI',
      address: {
        56: '0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e',
        97: '0x48638c96a3a0bde8fC22B58c59471761511D454c',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    yfii: {
      symbol: 'YFII',
      address: {
        56: '0x7f70642d88cf1c4a3a7abb072b53b929b653eda5',
        97: '0x52abAe1da22E819b21A9E97093A64D56A852f9eE',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    yooshi: {
      symbol: 'YOOSHI',
      address: {
        56: '0x02ff5065692783374947393723dba9599e59f591',
        97: '0x0d208f84ABBbc81c1D0Ff70d6d87d848061dE43A',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    yummy: {
      symbol: 'YUMMY',
      address: {
        56: '0x05f2df7b3d612a23fe12162a6c996447dce728a5',
        97: '0x5646d28db0353afA8f7eAD6464b94174ec2b4046',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    zec: {
      symbol: 'ZEC',
      address: {
        56: '0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb',
        97: '0xeD82b59bD172CA5604490d21f2BAD52BB39cfe7B',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    zee: {
      symbol: 'ZEE',
      address: {
        56: '0x44754455564474a89358b2c2265883df993b12f0',
        97: '0x68f7Fd76E655B73ff27b8EDd0cFc3E89E67762Ec',
      },
      decimals: 18,
      projectLink: 'https://babyswap.finance/',
    },
    zep: {
      symbol: 'ZEP',
      address: {
        56: '0x2E291e1c9f85a86d0C58Ae15621aaC005a8b2EAD',
        97: '0x2CA1467b5599B8267Ed21b400f808CC2F7c111f3',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
    zil: {
      symbol: 'ZIL',
      address: {
        56: '0xb86abcb37c3a4b64f74f59301aff131a1becc787',
        97: '0x7b0Ce875B1559848420B536A74C6a5db7dcEaf24',
      },
      decimals: 12,
      projectLink: 'https://babyswap.finance/',
    },
    shark: {
      symbol: 'SHARK',
      address: {
        56: '0xcc9b175e4b88a22543c44f1cc65b73f63b0d4efe',
        97: '0x7b0Ce875B1559848420B536A74C6a5db7dcEaf24',
      },
      decimals: 9,
      projectLink: 'https://www.babysharktoken.com/',
    },
    shibby: {
      symbol: 'SHIBBY',
      address: {
        56: '0xb1035523a844371c2877f8a3b2f2f8d337403b6f',
        97: '0x7b0Ce875B1559848420B536A74C6a5db7dcEaf24',
      },
      decimals: 9,
      projectLink: 'https://babyswap.finance/',
    },
  }
  
  export default tokens
  