import random from 'lodash/random'

// Array of available nodes to connect to
export const nodes = [process.env.REACT_APP_NODE_1, process.env.REACT_APP_NODE_2, process.env.REACT_APP_NODE_3]
export const bridges = ['a', 'b', 'c', 'd', 'e', 'f']

export const getBridgeUrl = () => {
  const randomIndex = random(0, bridges.length - 1)
  return bridges[randomIndex]
}

const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1)
  return nodes[randomIndex]
}

export default getNodeUrl
