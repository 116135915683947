import { MenuEntry } from '@metaswap/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: '合约操作',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Farms',
        href: '/farms',
      },
      {
        label: 'Pools',
        href: '/pools',
      },
      {
        label: 'Stake',
        href: '/stake',
      },
      // {
      //   label: 'Ido',
      //   href: '/ido',
      // },
    ],
  },
  {
    label: 'New Banner管理',
    icon: 'NftIcon',
    href: '/banner',
    items: [
      {
        label: 'Banner',
        href: '/newbanner',
      },
      {
        label: '添加Banner',
        href: '/addnewbanner/0',
      },
    ],
  },
  {
    label: 'Banner管理',
    icon: 'NftIcon',
    href: '/banner',
    items: [
      {
        label: 'Banner',
        href: '/banner',
      },
      {
        label: '添加Banner',
        href: '/addbanner/0',
      },
    ],
  },
  {
    label: '回购销毁',
    icon: 'NftIcon',
    href: '/column',
  },
  {
    label: 'Bottle管理',
    icon: 'NftIcon',
    href: '/bottlesManage',
    items: [
      {
        label: 'Bottle管理',
        href: '/bottlesManage',
      },
      {
        label: 'BottleEvent链接配置',
        href: '/bottlesEvent',
      },
    ],
  },
  {
    label: 'Swapfee管理',
    icon: 'NftIcon',
    href: '/swapfeeManage',
    items: [
      {
        label: 'Swapfee列表',
        href: '/swapfeeManage',
      },
      {
        label: '添加DistoryPair',
        href: '/addDistoryPair/0',
      },
    ],
  },
  {
    label: 'Market manange',
    icon: 'NftIcon',
    href: '/marketManage',
    items: [
      {
        label: 'market',
        href: '/marketManage',
      },
      {
        label: '添加collection',
        href: '/addCollection/0',
      },
    ],
  },
  {
    label: 'Token Approve',
    icon: 'TradeIcon',
    href: '/tokenapprove',
  },
  {
    label: 'LuckyBaby',
    icon: 'TradeIcon',
    href: '/luckybaby',
  },
  {
    label: 'TopGainer',
    icon: 'TradeIcon',
    href: '/topgainer',
  },
  {
    label: 'PriceBot',
    icon: 'TicketIcon',
    href: '/priceBot',
    // items: [
    //   {
    //     label: '添加币种',
    //     href: '/'
    //   }
    // ]
  },
  {
    label: 'WebSite',
    icon: 'TicketIcon',
    href: '/website',
    // items: [
    //   {
    //     label: '添加币种',
    //     href: '/'
    //   }
    // ]
  },
  {
    label: 'LandAudit',
    icon: 'TradeIcon',
    href: '/landaudit',
  },
  {
    label: 'LandBioAudit',
    icon: 'TradeIcon',
    href: '/landbioaudit',
  },
  {
    label: 'Referral',
    icon: 'TradeIcon',
    href: '/referral',
  },
  // {
  //   label: '币种管理',
  //   icon: 'TicketIcon',
  //   href: '/tokens',
  //   // items: [
  //   //   {
  //   //     label: '添加币种',
  //   //     href: '/'
  //   //   }
  //   // ]
  // },
  // {
  //   label: 'Collectibles',
  //   icon: 'NftIcon',
  //   href: '/collectibles',
  // },
  // {
  //   label: 'Team Battle',
  //   icon: 'TeamBattleIcon',
  //   href: '/competition',
  // },
  // {
  //   label: 'Teams & Profile',
  //   icon: 'GroupsIcon',
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: '/teams',
  //     },
  //     {
  //       label: 'Task Center',
  //       href: '/profile/tasks',
  //     },
  //     {
  //       label: 'Your Profile',
  //       href: '/profile',
  //     },
  //   ],
  // },
]

export default config
