import React from 'react'
import { Router, Switch } from 'react-router-dom'
import { ResetCSS } from '@metaswap/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import GlobalStyle from './style/Global'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import EasterEgg from './components/EasterEgg'
import routes from "./router/routes"
import AuthHOC from "./router/authRoute"
import history from './routerHistory'
import 'antd/dist/antd.css'



// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  useEagerConnect()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            {
              routes.map((item, index) => {
                return (
                  <AuthHOC  config={routes}/>
                )
              })
            }
          </Switch>
        </SuspenseWithChunkError>
      <EasterEgg iterations={2} />
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)
